<script>
export default {
  name: "BlockReport",
  components: {},
  props: {
    onCloseFunc: null,
    windowId: null
  },
  methods: {
    closeModalDialogButton() {
      this.onCloseFunc(this);
    }
  }
}
</script>

<template>
  <transition name="modal">
    <div class="modal-mask warning-important">
      <div class="modal-wrapper">
        <div class="modal fade show" style="display: block;">
          <div class="modal-fullscreen modal-dialog" role="document">
            <div class="modal-content" style="overflow-y: scroll;">
              <div class="modal-header">

                <div class="offset-1 col-10" style="opacity: 0.8">
                  Зворотній зв'язок: <a href="mailto:root@kadastr.live">root@kadastr.live</a>
                  <p>(за цією адресою доступ не отримати)</p>
                </div>
                <!--                <button class="close_button btn-close btn-close-white" type="button" @click="closeModalDialogButton()"-->
                <!--                        aria-label="Close">-->
                <!--                  <span aria-hidden="true">&times;</span>-->
                <!--                </button>-->
              </div>
              <div class="modal-body" style="overflow-y: initial;">
                <div class="row">
                  <div class="offset-1 col-10">
                    <figure>
                      <blockquote class="blockquote">
                        <p>
                          Доступ на цей ресурс заблоковано ухвалою Печерського
                          районного суду міста Києва від 28.08.2023 у справі № 757/28575/23-к.
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer text-end">
                        <a target="_blank" href="https://nkrzi.gov.ua/index.php?r=site/index&pg=171&id=9613">
                          Офіційний сайт НКРЗІ
                        </a>
                      </figcaption>
                    </figure>
                  </div>

                  <div class="col-10">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </div>

                  <div class="offset-1 col-10">
                    З питань відновлення доступу до офіційної публічної
                    кадастрової карти рекомендується направляти звернення до:

                    <ul>
                      <li>
                        <a href="https://minagro.gov.ua/gromadyanam/zvernennya-gromadyan/feedback" target="_blank">
                          Міністерства аграрної політики та продовольства України
                        </a>, email: <a href="mailto:support@minagro.gov.ua">support@minagro.gov.ua</a>
                      </li>
                      <li>
                        <a href="https://land.gov.ua/napysaty-zvernennia/" target="_blank">
                          Державної служби України з питань геодезії, картографії та кадастру
                        </a>, email: <a href="mailto:land@land.gov.ua">land@land.gov.ua</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-10">
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                  </div>

                  <br>
                  <br>
                  <br>
                  <br>
                  <div class="col-10 offset-1">
                    <p>Приблизний шаблон звернення:</p>
                  </div>
                  <div class="offset-2 col-9">
                    <p>
                      Прошу розглянути питання відкриття доступу до публічної кадастрової карти.
                    </p>
                    <p>
                      З лютого 2022 року публічна кадастрова карта (http://map.land.gov.ua/) не працює,
                      що створює значні незручності в моїй роботі. Без кадастрової карти неможливо визначити
                      місцезнаходження ділянки, її суміжні ділянки, значно ускладнено пошук вільної землі,
                      моніторинг використання земель.
                    </p>
                    <p>
                      З листопада 2023 року ситуація значно погіршилась у зв'язку з тим, що
                      архівне дзеркало ППК було заблоковано ухвалою Печерського районного суду міста Києва.
                    </p>
                    <p>
                      Вважаю, що суспільний інтерес до інформації з ППК значно перевищує
                      шкоду від її публікації в теперішніх умовах
                      (до того ж, чутливі дані на офіційній карті можна відфільтрувати).
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>

.show {
  opacity: 1;
}

.warning-important {
  background-color: #99000c !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  text-align: justify;
  top: 10%;
}

.modal-wrapper {
  text-shadow: 1px 1px 3px black;
}

.modal-default-button {
  display: block;
  margin-top: 1rem;
}


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-content {
  background: none;
  color: white;
}

a {
  color: white;
  text-underline: wheat;

  &:hover {
    color: white;
  }
}

a[target=_blank]:after {
  content: "\f08e";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-left: 5px;
}

li {
  margin-top: 20px;
}

.modal-header {
  text-align: right;
  position: relative;
  border: 0;
}

.close_button {
  position: absolute;
  right: 45px;
  top: 45px;
}

.blockquote-footer {
  color: white;
}

.blockquote-footer a {
  color: white;
}

.icon-grave {
  width: 180px;
  height: 180px;
  -webkit-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, .7));

  opacity: 0.2;
}

</style>