<template>
  <div class="breadcrumbs">
    <div class="container">
      <ul>
        <li>
          <router-link to="/">Головна</router-link>
        </li>
        <li>
          <router-link active-class="active" :to="'/parcel/' + $route.params.pk">{{ $route.params.pk }}</router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="container text-block" v-if="parcelInfo">
    <div class="row">
      <div class="col-md-12">
        <h1>Інформація про земельну ділянку</h1>
        <div class="alert alert-danger">
          Інформація є довідковою. Для отримання офіційної інформації зверніться до <a href="https://land.gov.ua/" target="_blank">ДЗК</a>.
        </div>
        <h2>{{ parcelInfo.cadnum }} <a class="btn btn-light" role="button" target="_blank"
           :href="'https://e.land.gov.ua/back/cadaster/?cad_num=' + parcelInfo.cadnum">
          Інформація про речові права
        </a></h2>

      </div>
      <div class="col-md-8">
          <table class="table">
            <tbody>
            <tr>
              <td>Кадастровий номер</td>
              <td>{{ parcelInfo.cadnum }}</td>
            </tr>
            <tr>
              <td>площа</td>
              <td>{{ parcelInfo.area }} {{ parcelInfo.unit_area }}</td>
            </tr>
            <tr>
              <td>власність</td>
              <td>{{ parcelInfo.ownership || "немає даних" }}</td>
            </tr>
            <tr>
              <td>використання</td>
              <td>{{ parcelInfo.use || "немає даних" }}</td>
            </tr>
            <tr>
              <td>призначення</td>
              <td>{{ parcelInfo.purpose || "немає даних" }}</td>
            </tr>
            <tr>
              <td>категорія</td>
              <td>{{ parcelInfo.category || "немає даних" }}</td>
            </tr>
            <tr>
              <td>адреса</td>
              <td>{{ parcelInfo.address || "немає даних" }}</td>
            </tr>
            <tr>
              <td>нормативна грошова оцінка</td>
              <td v-if="parcelInfo.valuation_value">{{ parcelInfo.valuation_value }} грн<span v-if="parcelInfo.valuation_date"> від {{ moment(parcelInfo.valuation_date * 1000).format('YYYY-MM-DD') }}</span></td>
              <td v-else>немає даних</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-4" style="min-height: 300px">
          <GeoJSONDisplay :geo-feature="parcelInfo.geometry"/>
        </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2918248999695345"
            crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style="display:block"
            data-ad-format="autorelaxed"
            data-ad-client="ca-pub-2918248999695345"
            data-ad-slot="3832360185"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
      </div>
    </div>

  </div>

</template>

<script>
import axios from "axios";
import moment from "moment";
import GeoJSONDisplay from "@/components/GeoJSONDisplay";

export default {
  name: "ParcelInfoPage",
  components: {GeoJSONDisplay},
  data() {
    return {
      parcelInfo: null
    }
  },
  methods: {
    moment(dateTime) {
      return moment(dateTime);
    }
  },

  mounted() {
    axios.get(
        `/api/parcels/${this.$route.params.pk}/history/`
    ).then(response => {
      this.parcelInfo = response.data;
    });
  }
}
</script>

<style scoped>

</style>
