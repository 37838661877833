<template>
  <label style="margin-bottom: 10px" :for="id">Растрові зображення</label>
  <div class="input-group">
    <!-- TODO: replace all domains hardcode -->
    <input type="text"
           :id="id"
           class="form-control font-monospace"
           readonly
           :value="text">

    <span @click="copyToClipboard" class="input-group-addon btn" title="Click to copy">
      <i class="fa fa-clipboard" aria-hidden="true"></i>
    </span>
  </div>
</template>
<script>
export default {
  name: 'CopyTextField',
  props: {
    text: {type: String},
    id: {type: String}
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.text);
    }
  }
}
</script>