<template>
  
  <div class="breadcrumbs">
    <div class="container">
      <ul>
        <li>
          <router-link to="/">Головна</router-link>
        </li>
        <li>
          <router-link to="/about">Про проєкт</router-link>
        </li>
      </ul>
    </div>
  </div>
  <div class="container text-block">

    <div class="row" style="margin-bottom: 50px;">
      <div class="col-md-12">
        <h2>
          Підключення до сторонніх програм
        </h2>
        <RasterLayers/>
      </div>
    </div>
  </div>

  <div class="container text-block">

  <div class="row" style="margin-bottom: 50px;">
    <div class="col-md-12">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2918248999695345"
          crossorigin="anonymous"></script>
      <!-- bottom about page -->
      <ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-2918248999695345"
          data-ad-slot="4653190099"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
      <script>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </script>
    </div>
  </div>
  </div>
  
  
  <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <p class="col-md-4 mb-0 text-muted">&copy; 2023</p>

      <a href="/" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <svg class="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"/></svg>
      </a>

      <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item"><a href="mailto:root@kadastr.live">root@kadastr.live</a></li>
      </ul>
    </footer>
  </div>

</template>

<script>
import RasterLayers from "@/components/about/include/RasterLayers";
export default {
  name: 'AboutPageComponent',
  components: {RasterLayers}
}
</script>
