<template>
  <div id="searchBox"
       ref="element"
       class="mapboxgl-ctrl mgl-searchControl"
       @focusout="onSearchEnded">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-md-12">
        <div class="search autocomplete">
          <div class="form">
            <i class="fa fa-search"></i>
            <input type="text"
                   class="form-control form-input"
                   autocomplete="off"
                   @input="onSearchChanged"
                   v-model="searchText"
                   placeholder="Введіть дані для пошуку...">
            <span class="stick-right">

              <span class="left-pan">
                <VTooltip
                    :offset="[0, 16]"
                >
                  <router-link to="/about">
                    <i class="fa fa-info-circle"></i>
                  </router-link>

                  <template #popper>
                    Інформація про проект
                  </template>
                </VTooltip>

              </span>
            </span>

          </div>
          <ul class="autocomplete-results" v-if="searchResults && searchResults.results.length > 0">
            <li class="autocomplete-result" v-for="result in searchResults.results" :key="result">
              <a class="autocomplete-link" href="#" @click="onSelectParcel(result)">
                <div class="cadnum_result_data">{{ result.cadnum }}</div>
                <div class="address_result_data"><b>Адреса: </b>{{ result.address ? result.address : 'Дані відсутні' }}
                </div>
                <div class="area_result_data">{{ result.area }} {{ result.unit_area }}</div>
              </a>
            </li>
          </ul>
          <ul class="autocomplete-results" v-else-if="searchResults && searchResults.error">
            <li class="autocomplete-result disabled">
              Під час пошуку виникла помилка. Повторіть спробу пізніше.
            </li>
          </ul>
          <ul class="autocomplete-results" v-else-if="searchResults && searchResults.results.length === 0">
            <li class="autocomplete-result disabled">
              Результати не знайдені. Спробуйте змінити пошуковий запис.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="height: 55px"></div>
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: 'SearchBox',
  data() {
    return {
      searchText: null,
      lastCall: null,
      lastCallTimer: null,
      searchResults: null
    }
  },
  props: {
    downloadAvailable: null
  },
  methods: {
    onAdd() {
      return this.$refs.element
    },
    onRemove() {

    },
    onInputEnd() {

    },
    downloadGeoJson() {
      if (this.downloadAvailable) {
        this.$emit('download');
      }
    },
    onSelectParcel(parcelData) {
      this.$emit('select', parcelData);
      this.searchResults = null;
    },
    debounce(f, t) {
      return function (args) {
        let previousCall = this.lastCall;
        this.lastCall = Date.now();
        if (previousCall && ((this.lastCall - previousCall) <= t)) {
          clearTimeout(this.lastCallTimer);
        }
        this.lastCallTimer = setTimeout(() => f(args), t);
      }.bind(this)
    },
    search() {
      return this.debounce((searchText) => {
        axios.get(
            `/search/${searchText}/`
        ).then(response => {
          this.searchResults = response.data;
        }).catch(error => {
          this.searchResults = {
            'results': [],
            'error': error
          };
          console.warn('search error', error)
        });
      }, 500)
    },
    onSearchChanged() {
      if (this.searchText) {
        this.search()(this.searchText);
      }
      else {
        this.searchResults = null;
      }

    },
    onSearchEnded(e) {
      if (e.relatedTarget && e.relatedTarget.className !== "maplibregl-canvas") {
        return;
      }
      this.searchResults = null;
    }
  }
}
</script>

<style scoped>

.autocomplete-results {
  background: white;
  border: 1px solid #ced4da;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 95%;
}

.autocomplete-result {
  list-style: none;
  margin-top: 3px;
  margin-bottom: 3px;
}

.autocomplete-link {
  display: block;
  position: relative;
}

.autocomplete-link .area_result_data {
  right: 0;
  top: 0;
  position: absolute;
}

.cadnum_result_data {
  font-weight: 600;
}

.autocomplete-result:not(:last-child) {
  border-bottom: 1px solid #fafafa;
}

.autocomplete-result:hover {
  background: #ecebeb;
  &.disabled {
    background: inherit;
  }
}

.form {
  position: relative
}

.form .fa-search {
  position: absolute;
  top: 13px;
  left: 20px;
  color: #9ca3af
}

.form .left-pan {
  display: inline-block;
  border-left: 1px solid #d1d5db;
  margin-right: 1px;
}

.form .stick-right {
  position: absolute;
  right: 7px;
  top: 10px;
  padding: 2px;
}

.left-pan {
  padding-left: 10px;
  padding-right: 10px;

  font-size: 120%;
}

.form-input {
  height: 40px;
  text-indent: 33px;
  border-radius: 0;
  font-size: 14px;
}

.form-input:focus {
  box-shadow: none;
  border: none
}

.fa-warning {
  color: #ff9966;
}

</style>